import NavBar from "./Navbar";
import NavbarMobile from "./Navbar-Mobile";
import Projects from "./Projects";
import { Routes, Route } from 'react-router-dom';
import Home from "./Home";
import Skills from "./Skills";
import About from "./About";
import Contact from "./Contact";
import CV from "./CV";
import { BrowserRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import '../App.css';
import "animate.css/animate.min.css";

function App() {

  return (
      <div>
          <BrowserRouter>
            <MediaQuery maxWidth={1090}>
              <NavbarMobile />  
            </MediaQuery>
            <MediaQuery minWidth={1100}>
              <NavBar />  
            </MediaQuery>
            <div className="sections-container background">
                <Routes>
                    <Route exact path="/" element={ <Home /> } />
                    <Route path="#about-section" element={ <About /> } />
                    <Route path="#skills-section" element={ <Skills /> } />
                    <Route path="#project-section" element={ <Projects /> } />
                    <Route path="#contact-section" element={ <Contact /> } />
                    <Route path="/cv" element={ <CV /> } />
                    </Routes>
            </div>
          </BrowserRouter>
      </div>
  );
}

export default App;
