import './Projects.css';
import CryptoPage from '../images/Crypto-Image.png';
import GeoWhere from '../images/GeoWhere.png';
import PokemonParty from '../images/PokemonParty.png';
import ChronoSphere from '../images/Chronosphere.png';
import RubyImage from '../images/Ruby.png';
import JavascriptImage from '../images/Javascript.png';
import ReactImage from '../images/React.png';
import FirebaseImage from '../images/Firebase.png';
import HerokuImage from '../images/Heroku.png';
import MaterialUIImage from '../images/MaterialUI.png';
import SupabaseImage from '../images/Supabase.jpeg'
import HTMLImage from '../images/html.png';
import CSSImage from '../images/css.png';
import PostgresqlImage from '../images/Postgresql.png'
import UnityImage from '../images/Unity.png'
import CSharpImage from '../images/Csharp.webp'
import EscapeRoomImage from '../images/Escape-Room.png'
import BananagramsImage from '../images/Bananagrams.png'
import ToDoListImage from '../images/To-Do-List.png'
import TailwindImage from '../images/Tailwind.png'
import PythonImage from '../images/Python.png'
import DjangoImage from '../images/Django.png'
import ExpressImage from '../images/Express.webp'
import MongoDBImage from '../images/MongoDB.png'
import { Button } from '@mui/material';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Tooltip } from '@mui/material';

const Projects = ({aboutRef, skillsRef, projectsRef, contactRef}) => {
    return (
        <div id='project-section' ref={projectsRef}>
            <h1>Projects</h1>
            <div>
            <AnimationOnScroll animateIn="animate__bounceInLeft" animateOnce={true}>
                <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/Crypto-Page">
                                <img src={CryptoPage}  id="image" alt="link to Crypto Pricing website" /> 
                            </a>
                        </div>
                        <h1>Ceege Crypto</h1>
                        <div className='project-text'>
                            <ul>
                                <li>A crypto pricing website watching the top 100 coins in realtime.</li>
                                <li>Graphs showing the rise and fall over the last 24 hours.</li>
                                <li>Add your favourite coins to your profile and play a mini investment game to see how much you would of gained or lost over the past 24 hours if you put a set amount of money into it.</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="HTML" arrow>
                                    <img src={HTMLImage} className="icons" alt='HTML icon' />    
                                </Tooltip>
                                <Tooltip title="CSS" arrow>
                                    <img src={CSSImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                                <Tooltip title="Javascript" arrow>
                                    <img src={JavascriptImage} className="icons" alt='Javascript icon' />    
                                </Tooltip>
                                <Tooltip title="React" arrow>
                                    <img src={ReactImage} className="icons" alt='React icon' />    
                                </Tooltip>
                                <Tooltip title="Firebase" arrow>
                                    <img src={FirebaseImage} className="icons" alt='Firebase icon' />    
                                </Tooltip>
                                <Tooltip title="Material UI" arrow>
                                    <img src={MaterialUIImage} className="icons" alt='Material UI icon' />   
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button className='button' variant='contained' target="_blank" href="https://ceegecrypto.firebaseapp.com/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__bounceInRight" animateOnce={true}>
                <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/GeoWhere-Testing">
                                <img src={GeoWhere} id="image" alt="link to GeoWhere site" />
                            </a>    
                        </div>
                        <h1>GeoWhere</h1>
                        <div className='project-text'>
                            <ul>
                                <li>A streetview location guessing game.</li>
                                <li>5 rounds of placing a marker where you think you are in the world and then points based on how close your guess was to the correct answer.</li>
                                <li>Show off your high score against your friends and other people who have played around the world</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="HTML" arrow>
                                    <img src={HTMLImage} className="icons" alt='HTML icon' />    
                                </Tooltip>
                                <Tooltip title="CSS" arrow>
                                    <img src={CSSImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                                <Tooltip title="Javascript" arrow>
                                    <img src={JavascriptImage} className="icons" alt='Javascript icon' />    
                                </Tooltip>
                                <Tooltip title="React" arrow>
                                    <img src={ReactImage} className="icons" alt='React icon' />    
                                </Tooltip>
                                <Tooltip title="Supabase" arrow>
                                    <img src={SupabaseImage} className="icons" alt='Supbase icon' />    
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button variant='contained' target="_blank" href="https://geowhere.netlify.app/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__bounceInLeft" animateOnce={true}>
                    <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/School-Notes-V2">
                                <img src={ToDoListImage} id="image" alt="link to the To Do List repo" />
                            </a>    
                        </div>
                        <h1>To-Do List</h1>
                        <div className='project-text'>
                            <ul>
                                <li>Create draggable categories for your To-Do Lists and then add Tasks to those categories</li>
                                <li>Have a priority / time cross where you can visually see what categories of tasks are more pressing to do than others</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="HTML" arrow>
                                    <img src={HTMLImage} className="icons" alt='HTML icon' />    
                                </Tooltip>
                                <Tooltip title="CSS" arrow>
                                    <img src={CSSImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                                <Tooltip title="React" arrow>
                                    <img src={ReactImage} className="icons" alt='React icon' />    
                                </Tooltip>
                                <Tooltip title="Express" arrow>
                                    <img src={ExpressImage} className="icons" alt='Express icon' />    
                                </Tooltip>
                                <Tooltip title="MongoDB" arrow>
                                    <img src={MongoDBImage} className="icons" alt='MongoDB icon' />    
                                </Tooltip>
                                <Tooltip title="Heroku" arrow>
                                    <img src={HerokuImage} className="icons" alt='Heroku icon' />
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button variant='contained' target="_blank" href="https://school-notes-backend.onrender.com/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__bounceInRight" animateOnce={true}>
                    <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/Bananagram">
                                <img src={BananagramsImage} id="image" alt="link to the banagrams repo" />
                            </a>    
                        </div>
                        <h1>Bananagrams</h1>
                        <div className='project-text'>
                            <ul>
                                <li>Fast and frantic word game where you are trying to create words using tiles (Similar to Scrabble)</li>
                                <li>You can peel: Whenever a player uses the last of their tiles, everyone gets another tile, dump: where you can throw away 1 tile for 3 more</li>
                                <li>And when you end the game and think that all your words are correct you can call Bananas which will get a Dictionary API to check if your words work</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="HTML" arrow>
                                    <img src={HTMLImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                                <Tooltip title="CSS" arrow>
                                    <img src={CSSImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                                <Tooltip title="Javascript" arrow>
                                    <img src={JavascriptImage} className="icons" alt='HTML icon' />    
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button variant='contained' target="_blank" href="https://bissmark.github.io/BananaGramsSinglePlayer/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__bounceInLeft" animateOnce={true}>
                    <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/escape-room">
                                <img src={EscapeRoomImage} alt="link to the escape room github repo" />
                            </a>    
                        </div>
                        <h1>Escape Room</h1>
                        <div className='project-text'>
                            <ul>
                                <li>Play intricate escape rooms using user created puzzles</li>
                                <li>Implement different visual locks and have each puzzle link to the next to create modular escape rooms where each room can be different than the last</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="Javascript" arrow>
                                    <img src={JavascriptImage} className="icons" alt='Javascript icon' />    
                                </Tooltip>
                                <Tooltip title="Python" arrow>
                                    <img src={PythonImage} className="icons" alt='Python icon' />    
                                </Tooltip>
                                <Tooltip title="Django" arrow>
                                    <img src={DjangoImage} className="icons" alt='Django icon' />    
                                </Tooltip>
                                
                            </p>
                            <p className='tech-stack'>
                                <Tooltip title="Tailwind" arrow>
                                    <img src={TailwindImage} className="icons" alt='Tailwind icon' />    
                                </Tooltip>
                                <Tooltip title="Postgresql" arrow>
                                    <img src={PostgresqlImage} className="icons" alt='Postgresql icon' />    
                                </Tooltip>
                                <Tooltip title="Heroku" arrow>
                                    <img src={HerokuImage} className="icons" alt='Heroku icon' />    
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button variant='contained' target="_blank" href="https://escape-room-birthday-a367acceb2ce.herokuapp.com/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__bounceInLeft" animateOnce={true}>
                <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/project1-pokemon">
                                <img src={PokemonParty} id="image" alt="link to the pokemon planner website" />
                            </a>    
                        </div>
                        <h1>Pokemon Party Planner</h1>
                        <div className='project-text'>
                            <ul>
                                <li>A Pokemon pokedex comprising of Generation 1 pokemon and moves.</li>
                                <li>Allows you to build a party of 6 pokemon, selecting from the pokedex.</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="HTML" arrow>
                                    <img src={HTMLImage} className="icons" alt='HTML icon' />    
                                </Tooltip>
                                <Tooltip title="CSS" arrow>
                                    <img src={CSSImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                                <Tooltip title="Ruby" arrow>
                                    <img src={RubyImage} className="icons" alt='Ruby icon' />    
                                </Tooltip>
                                <Tooltip title="Postgresql" arrow>
                                    <img src={PostgresqlImage} className="icons" alt='Postgresql icon' />    
                                </Tooltip>
                                <Tooltip title="Heroku" arrow>
                                    <img src={HerokuImage} className="icons" alt='Heroku icon' />   
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button variant='contained' target="_blank" href="https://fragrant-snowflake-3767.fly.dev/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__bounceInRight" animateOnce={true}>
                <div className='project-items'>
                    <div className='grey-box'>
                        <div className='image-div'>
                            <a href="https://github.com/Bissmark/chronosphere-webgl">
                                <img src={ChronoSphere} id="image" alt="link to the pokemon planner website" />
                            </a>    
                        </div>
                        <h1>Chronosphere</h1>
                        <div className='project-text'>
                            <ul>
                                <li>A ball platformer game where the aim is to traverse through an obstacle course to make your way to the end.</li>
                                <li>Dodge spikes, teleport around the field, climb platforms and reach the time gate without falling off the course.</li>
                            </ul>
                            <p className='tech-stack'>
                                <Tooltip title="Unity" arrow>
                                    <img src={UnityImage} className="icons" alt='HTML icon' />    
                                </Tooltip>
                                <Tooltip title="C#" arrow>
                                    <img src={CSharpImage} className="icons" alt='CSS icon' />    
                                </Tooltip>
                            </p>
                            <div className='image-div'>
                                <Button variant='contained' target="_blank" href="https://bissmark.github.io/chronosphere-webgl/">
                                    Demo
                                </Button>    
                            </div>
                        </div>
                    </div>
                </div>
                </AnimationOnScroll>
            </div>
        </div>
    )
}

export default Projects;