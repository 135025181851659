import './Skills.css';
import MaterialUIImage from '../images/MaterialUI.png';
import SupabaseImage from '../images/Supabase.jpeg'
import HTMLImage from '../images/html.png';
import CSSImage from '../images/css.png';
import RubyImage from '../images/Ruby.png';
import JavascriptImage from '../images/Javascript.png';
import ReactImage from '../images/React.png';
import FirebaseImage from '../images/Firebase.png';
import CSharpImage from '../images/Csharp.webp';
import UnityImage from '../images/Unity.png';
import CplusplusImage from '../images/C++.png';
import GitImage from '../images/Git.svg';
import MySQLImage from '../images/MySQL.png'
import PythonImage from '../images/Python.png'
import TailwindImage from '../images/Tailwind.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Tooltip } from '@mui/material';

const Skills = ({ skillsRef }) => {
    return (
        <div id='skills-section' ref={skillsRef}>
            <h1>My Skills</h1>
            {/* <AnimationOnScroll animateIn="animate__zoomIn" animateOnce={true}> */}
            <div className='grey-box'>
                <h2>Skills and Experience</h2>
                <p>I have built 7 websites, 3 of them being games, a Crypto Pricing website, a Escape Room website, a To-Do List and a Pokemon Pokdex Party Planner</p>
                <p>I have good technical skills in: </p>
                <div>
                    <p className='tech'>
                        <Tooltip title="HTML" arrow>
                            <img src={HTMLImage} className="icons" alt='HTML icon' />    
                        </Tooltip>
                        <Tooltip title="CSS" arrow>
                            <img src={CSSImage} className="icons" alt='CSS icon' />    
                        </Tooltip>
                        <Tooltip title="Javascript" arrow>
                            <img src={JavascriptImage} className="icons" alt='Javascript icon' />    
                        </Tooltip>
                        <Tooltip title="React" arrow>
                            <img src={ReactImage} className="icons" alt='React icon' />    
                        </Tooltip>
                        <Tooltip title="Firebase" arrow>
                            <img src={FirebaseImage} className="icons" alt='Firebase icon' />    
                        </Tooltip>
                    </p>
                    <p className='tech'>
                        <Tooltip title="C#" arrow>
                            <img src={CSharpImage} className="icons" alt='C# icon' />    
                        </Tooltip>
                        <Tooltip title="Unity" arrow>
                            <img src={UnityImage} className="icons" alt='Unity icon' />    
                        </Tooltip>
                        <Tooltip title="Git" arrow>
                            <img src={GitImage} className="icons" alt='Git icon' />    
                        </Tooltip>
                        <Tooltip title="Ruby" arrow>
                            <img src={RubyImage} className="icons" alt='Ruby icon' />    
                        </Tooltip>
                        <Tooltip title="C++" arrow>
                            <img src={CplusplusImage} className="icons" alt='C++ icon' />    
                        </Tooltip>
                    </p>
                    <p className='tech'>
                        <Tooltip title="MySQL" arrow>
                            <img src={MySQLImage} className="icons" alt='MySQL icon' />    
                        </Tooltip>
                        <Tooltip title="Material UI" arrow>
                            <img src={MaterialUIImage} className="icons" alt='MaterialUI icon' />    
                        </Tooltip>
                        <Tooltip title="Supabase" arrow>
                            <img src={SupabaseImage} className="icons" alt='Supabase icon' />    
                        </Tooltip>
                        <Tooltip title="Python" arrow>
                            <img src={PythonImage} className="icons" alt='Python icon' />    
                        </Tooltip>
                        <Tooltip title="Tailwind" arrow>
                            <img src={TailwindImage} className="icons" alt='Tailwind icon' />    
                        </Tooltip>
                    </p>
                </div>
            </div>
            {/* </AnimationOnScroll> */}
        </div>
    )
}

export default Skills;