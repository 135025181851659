import React, { useState } from 'react';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import './Navbar-Mobile.css'
import { Link } from 'react-router-dom';

const NavbarMobile = ({aboutRef, skillsRef, projectsRef, contactRef}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleScroll = (ref) => {
        setAnchorEl(null);
        window.scrollTo({
          top: ref.offsetTop,
          left: 0,
          behavior: "smooth",
        });
        
    };

    const linkStyle = {
        margin: "1rem",
        textDecoration: "none",
        color: 'white',
        display: "flex",
        justifyContent: "center",
        fontFamily: 'Uni ui-sans-serif'
    };

    return (
        <div className='navbar'>
            <div className='navlinks-mobile'>
                <div className='left-side'>
                    <Button
                        variant='link'
                        size='extra-large'
                        onClick={handleClick}
                        startIcon={ <MenuIcon /> }
                        sx={{ height: '60px' }}
                    >
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        sx={{ textAlign: 'center'}}
                        PaperProps={{
                            style: {
                                width: '100%',
                                textAlign: "center",
                                borderRadius: "25px",
                                backgroundColor: "grey"
                            }
                        }}
                        onClose={handleClose}
                    >
                        <Link to="#about-section" style={linkStyle}  onClick={() => {handleScroll(aboutRef.current);}}>About</Link>
                        <Link to="#skills-section" style={linkStyle} onClick={() => {handleScroll(skillsRef.current);}}>My Skills</Link>
                        <Link to="#project-section" style={linkStyle} onClick={() => {handleScroll(projectsRef.current);}}>Projects</Link>
                        <Link to="#contact-section" style={linkStyle} onClick={() => {handleScroll(contactRef.current);}}>Contact</Link>
                    </Menu>
                </div>    
                <div className='right-side'>
                    <Button
                    variant='link'
                    size='extra-large'
                    startIcon={ <LinkedInIcon /> }
                    href="https://www.linkedin.com/in/cjholt/"
                    >
                    </Button>
                    <Button
                        variant='link'
                        size='extra-large'
                        startIcon={ <GitHubIcon /> }
                        href="https://github.com/Bissmark"
                    >
                    </Button>
                    <Button
                        variant='link'
                        size='extra-large'
                        startIcon={ <YouTubeIcon /> }
                        href="https://www.youtube.com/channel/UCkiCA2AtCdRxRd_G-svQ2PA"
                    >
                    </Button>      
                </div>
            </div>
        </div>
    )
}

export default NavbarMobile;