import React from 'react';
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
//import ArticleIcon from '@mui/icons-material/Article';
import './Navbar.css'
import Me from '../images/ProfilePic.jpg'
import Link from '@mui/material/Link';

const Navbar = () => {
  return (
    <div className='sidenav'>
      <div className='menu-top'>
        <img src={Me} className="logo" rel='home' alt=""></img>
        <h4>Christopher Holt</h4>
        <p>Full-Stack Software Engineer</p>
      </div>
      <div className='navlinks'>
        <Link href="#about-section" underline="none">About</Link>
        <Link href="#skills-section" underline="none">My Skills</Link>
        <Link href="#project-section" underline="none">Projects</Link>
        <Link href="#contact-section" underline="none">Contact</Link>
      </div>
      <ul className='socials'>
        <li>
          <Button
              variant='link'
              startIcon={ <LinkedInIcon /> }
              href="https://www.linkedin.com/in/cjholt/"
          >
          </Button>
        </li>
        <li>
          <Button
              variant='link'
              startIcon={ <GitHubIcon /> }
              href="https://github.com/Bissmark"
          >
          </Button>
        </li>
        <li>
          <Button
              variant='link'
              startIcon={ <YouTubeIcon /> }
              href="https://www.youtube.com/channel/UCkiCA2AtCdRxRd_G-svQ2PA"
          >
          </Button>
        </li>
        {/* <li>
          <Button
              variant='link'
              startIcon={ <ArticleIcon /> }
              href="/cv"
          >
          </Button>
        </li> */}
      </ul>
    </div>
  );
}

export default Navbar;