import './Contact.css'
import Button from '@mui/material/Button';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
}

const Contact = ({ contactRef }) => {
    return (
        <div id='contact-section' ref={contactRef}>
            <h1>Contact Me</h1>
            <AnimationOnScroll animateIn="animate__bounceIn" animateOnce={true}>
                <div className='grey-box' style={{marginBottom: '3em'}}>
                    <p>I am available for hire and excited for opportunities</p>
                    <h4>Email:
                        <Mailto email="holt.christopher1@gmail.com" subject="We would like to employ you">
                            <Button
                            variant='link'
                            sx={{fontSize: 12, display: 'flex', justifyContent: 'left', margin: '0 0 0 -1em'}}
                            startIcon={ <EmailIcon /> }
                            >
                            holt.christopher1@gmail.com
                            </Button>
                        </Mailto>
                    </h4>
                    <h4>LinkedIn: 
                        <Button
                        sx={{fontSize: 12, display: 'flex', justifyContent: 'left', margin: '0 0 0 -1em'}}
                        variant='link'
                        startIcon={ <LinkedInIcon /> }
                        href="https://www.linkedin.com/in/cjholt/"
                        >
                        cjholt
                        </Button> 
                    </h4>
                    <h4>Github: 
                        <Button
                        variant='link'
                        sx={{fontSize: 12, display: 'flex', justifyContent: 'left', margin: '0 0 0 -1em'}}
                        startIcon={ <GitHubIcon /> }
                        href="https://github.com/Bissmark"
                        >
                        Bissmark
                        </Button> 
                    </h4>
                    <h4>Youtube: 
                        <Button
                        variant='link'
                        sx={{fontSize: 12, display: 'flex', justifyContent: 'left', margin: '0 0 0 -1em'}}
                        startIcon={ <YouTubeIcon /> }
                        href="https://www.youtube.com/channel/UCkiCA2AtCdRxRd_G-svQ2PA"
                        >
                        Ceege
                        </Button> 
                    </h4>
                </div>
            </AnimationOnScroll>
        </div>
    )
}

export default Contact;