const About = ({ aboutRef }) => {
    return (
        <div id='about-section' ref={aboutRef}>
            <h1 className="">About me</h1>
            {/* <AnimationOnScroll animateIn="animate__pulse"> */}
                <div className='grey-box'>
                    <p>I am a Sydney based Junior Full-Stack Developer. I have a passion in Computers in General but more importantly in Coding, especially with my inventive ideas. I love problem solving, working in stressful environments and dedicating myself to whatever problem is thrown my way. I enjoy working with diverse people who can help me learn on this journey together.</p>
                    <p>I started in games programming in 2010 and worked there for 2 years before moving to gardening and then on to race horse handling, I have learnt many valuable skills such as working in difficult environments (working with horses) and taking many projects from their inception to the finished product (gardening).</p>
                    <p>In my freetime, I enjoy playing a wide variety of video games from Platformers, RPGs, MMO's, Soccer Simulators and Adventure games. I like to read Fantasy books, I enjoy cooking and walking.</p>
                </div>
            {/* </AnimationOnScroll> */}
        </div>
    )

}

export default About;